// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input, HostListener, ViewChild, ElementRef   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';


// rxjs
import { Subject } from 'rxjs';

// misc
import { WMLImage, WMLUIProperty, updateClassString,toggleClassString, selectRandomOptionFromArray, generateClassPrefix, WMLConstructorDecorator } from '@windmillcode/angular-wml-components-base';

import { WmlNotifyBarType } from '@windmillcode/angular-wml-notify';
import { WMLPanelItemParams } from '@windmillcode/angular-wml-panel';
import VanillaTilt from 'vanilla-tilt';
import { LabViewerZeroParams } from '../lab-viewer-zero/lab-viewer-zero.component';
import { WMLPopupParams } from '@windmillcode/angular-wml-popup';
import { triggerEvent } from '@core/utility/common-utils';


@Component({
  selector: 'card-zero',
  templateUrl: './card-zero.component.html',
  styleUrls: ['./card-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CardZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public el:ElementRef
  ) { }

  classPrefix = generateClassPrefix('CardZero')
  @Input('params') params: CardZeroParams = new CardZeroParams()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @ViewChild("mainPod",{static:true})  mainPod:ElementRef
  cardTypeClass  =this.classPrefix(`View`)+selectRandomOptionFromArray([0,1])
  classList:Array<string> = []
  updateClassString = updateClassString(this,"myClass","classList")
  ngUnsub= new Subject<void>()


  @HostListener("click",['$event.target'])
  toggleCard($evt:HTMLElement){
    if(!($evt.tagName === "A" ||  $evt.className === "CardZeroPod0Title0"  )){

      if(  this.params.type ==="expand"){
        toggleClassString(this.cardTypeClass+"Clicked",this.classList,this.updateClassString)
        this.openLink(this.params.resourceUrl)
      }
      else if(this.params.type ==="panel"){
        this.params.panel.toggle()
      }
      else if(this.params.type ==="lab"){
        this.params.popup.params = this.params.labViewer
        this.params.popup.togglePopupSubj.next(true)
      }

    }

  }

  openLink=(url:string)=>{
    if(!(this.params.type !== "project")){
      return
    }
    else if(!url){
      this.baseService.createWMLNote(
        "CardZero.wmlNotify.missingArticleLink",
        WmlNotifyBarType.Info
      )
    }
    else{
      window.open(url,'_blank')
    }
  }

  initVanillaTilt= ()=>{
    VanillaTilt.init(this.mainPod.nativeElement,{
      perspective:500,
    });
  }

  ngOnInit(): void {
    this.updateClassString(this.classPrefix(`View`))
    this.updateClassString(this.cardTypeClass)
    if(["expand","panel"].includes(this.params.type)){
      this.updateClassString(this.classPrefix(`ViewExpand`))
    }
    if(this.params.type ==="project"){
      this.updateClassString(this.classPrefix(`ViewProject`))
    }
    this.initVanillaTilt()
    this.params.click =()=>{
      triggerEvent(this.el.nativeElement,"click")
    }
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class CardZeroParams extends WMLUIProperty {
  constructor(params: Partial<CardZeroParams & {paramSummary?:string}> = {}) {
    super()
    let origParams = Object.entries(params)
      .filter(([key,val]) => {
        return !key.startsWith('param');
      });
    Object.assign(this, { ...Object.fromEntries(origParams) });
    if(![null,undefined].includes(params.paramSummary)){
      this.summary.text = params.paramSummary
    }
  }

  labViewer = new LabViewerZeroParams()
  popup = new WMLPopupParams()
  panel = new WMLPanelItemParams()
  project = new CardZeroProjectProps()
  override type:"expand" | "panel" | "lab" | "project"= "expand"
  title ="My Article Title"
  summary = new WMLUIProperty({
    text:`
      There's a report for that. Our 2023 Tech Forecast combines the top takeaways from Pluralsight's State of Upskilling report and State of Cloud report with user data from our platforms. Discover what makes employees 94% more likely to stay and why there’s a gap between tech leaders who want to develop in the cloud (75%) and technologists who have extensive cloud experience (8%).
    `
  })
  resourceUrl:string
  imgUrl =new WMLImage()


}

@WMLConstructorDecorator
export class CardZeroProjectProps {
  constructor(params: Partial<CardZeroProjectProps> = {}) { }

  get resources(){
    return [
      this.googlePlayStore,
      this.appleAppStore,
      this.webBrowser
    ]
  }
  googlePlayStore = new CardZeroWMLImage({
    src:"assets/media/projectsZero-google_play_store.svg",
    alt:"CardZero.googlePlayStore.imgAlt"
  })
  appleAppStore = new CardZeroWMLImage({
    src:"assets/media/projectsZero-apple-app-store.svg",
    alt:"CardZero.appleAppStore.imgAlt"
  })
  webBrowser= new CardZeroWMLImage({
    src:"assets/media/projectsZero-open-in-web.svg",
    alt:"CardZero.webBrowser.imgAlt"
  })
}


@WMLConstructorDecorator
export class CardZeroWMLImage extends WMLImage {
  constructor(params: Partial<CardZeroWMLImage> = {}) {super() }
  href:string
}

