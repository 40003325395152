// angular
import {
  ChangeDetectionStrategy,ChangeDetectorRef,Component,HostBinding,Input,QueryList,Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { BehaviorSubject, Subject, fromEvent, interval, of } from 'rxjs';
import { delay, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

// wml-components
import {WMLCustomComponent,WMLUIProperty,addCustomComponent,generateClassPrefix,generateRandomColor,generateRandomNumber,
} from '@windmillcode/angular-wml-components-base';

// misc

import { LinkedList, retriveValueFromPXUnit } from '@core/utility/common-utils';
import { CardTwoComponent, CardTwoParams } from '../card-two/card-two.component';

@Component({
  selector: 'carousel-zero',
  templateUrl: './carousel-zero.component.html',
  styleUrls: ['./carousel-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public renderer2: Renderer2
  ) {}

  classPrefix = generateClassPrefix('CarouselZero');
  @Input('params') params: CarouselZeroParams = new CarouselZeroParams();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @ViewChildren("customCards",{read:ViewContainerRef}) customCards:QueryList<ViewContainerRef>
  @ViewChild("chosenCard",{read:ViewContainerRef}) chosenCard:ViewContainerRef;
  ngUnsub = new Subject<void>();

  animateCarousel = ()=>{
    if(this.params.allowAnimationIfCaourselIsEmpty){
      let cardsLinkedList = new LinkedList(null,this.params.cards)
      cardsLinkedList.closeList()
      cardsLinkedList.moveToNextItemInList()
      return interval(this.params.autoPlayInterval)
      .pipe(
        takeWhile(()=>this.params.allowAnimationIfCaourselIsEmpty),
        takeUntil(this.ngUnsub),
        tap(()=>{
          cardsLinkedList.moveToNextItemInList()
          cardsLinkedList.list.val.option.click()

          this.chosenCard.remove(0)
          addCustomComponent(
            this.chosenCard,
            cardsLinkedList.list.val.cardProps.cpnt,
            cardsLinkedList.list.val.cardProps.params,
          )
          this.cdref.detectChanges()
        })
      )

    }
    else{
      return of({})
    }
  }

  updateCardHeight = (currentWidth) => (card: CarouselZeroCardParams) => {
    card.card.style.height = this.params.heightFactor * currentWidth + 'px';
  };

  updateCurrentCard = (targetCard:CarouselZeroCardParams,type:"option"|"card")=>{

    targetCard[type].click()
    this.chosenCard.remove(0)
    addCustomComponent(
      this.chosenCard,
      targetCard.cardProps.cpnt,
      targetCard.cardProps.params,
    )
    this.cdref.detectChanges()
  }

  updateZAxisBasedOnCardWidth = () => {

    return this.params.readyToInitCarouselSubj
    .pipe(
      takeUntil(this.ngUnsub),
      delay(1000),
      switchMap(() => {
        return this.resizeCardsOnWindowResize();
      })
    );
  };

  resizeCardsOnWindowResize() {
    return fromEvent(window, 'resize').pipe(
      startWith({}),
      takeUntil(this.ngUnsub),
      tap(() => {
        let cardWidth = this.getCardWidth();
        if (cardWidth >= this.params.initalCardWidth) {
          this.params._zAxisValue = this.params.initalZAxisValue;
        } else {
          this.params._zAxisValue =
            (cardWidth / this.params.initalCardWidth) *
            this.params.initalZAxisValue;
          this.params._zAxisValue *= 1.01;
        }

        let currentCard = this.params.cards.find((x) => x.option.isPresent);
        this.params.updateCarousel(currentCard)(
          this.updateCardHeight(cardWidth)
        );
        this.cdref.detectChanges();
      })
    );
  }

  getCardWidth() {
    let cardWidth;
    try {
      cardWidth = parseInt(
        retriveValueFromPXUnit(
          getComputedStyle(
            document.querySelector('.CarouselZeroPod1Item1')
          ).width
        )
      );
    } catch (error) {
      cardWidth = this.params.initalCardWidth;
    }
    return cardWidth;
  }

  populateCardComponents() {
    addCustomComponent(
      this.chosenCard,
      this.params.cards[0].cardProps.cpnt,
      this.params.cards[0].cardProps.params
    );
    this.customCards.forEach((customCard, index0) => {
      let customCpnt = this.params.cards[index0];
      addCustomComponent(
        customCard,
        customCpnt.cardProps.cpnt,
        customCpnt.cardProps.params
      );
    });
  }


  ngAfterViewInit() {
    this.populateCardComponents();
    this.params.initCarousel();
    this.params.cdref = this.cdref;
    this.updateZAxisBasedOnCardWidth().subscribe();
    this.animateCarousel().subscribe()
  }


  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class CarouselZeroParams {
  constructor(
    params: Partial<CarouselZeroParams> = {},
    addtl: Partial<{
      showOptionsContainer: 'true' | 'false';
    }> = {}
  ) {
    Object.assign(this, {
      ...params,
    });
    if (addtl.showOptionsContainer) {
      this.optionsContainer.isPresent = addtl.showOptionsContainer === 'true';
    }

    this.initCards();
  }
  allowAnimationIfCaourselIsEmpty = true
  nonChosenScaleFactor = 0.8;
  degree = 0;
  _zAxisValue = 540;
  initalZAxisValue = 540;
  initalCardWidth = 483.875;
  minCardWidth = 210;
  autoPlayInterval = 3000
  heightFactor = 0.44375
  carousel = new WMLUIProperty({});
  readonly readyToInitCarouselSubj = new BehaviorSubject(null);
  cdref: ChangeDetectorRef;
  cards:CarouselZeroCardParams[] = []
  optionsContainer = new WMLUIProperty();
  get chosenCard(){
    return this.cards.find((card)=> card.option.isPresent)
  }
  reInit = () => {
    this.initCards();
    this.initCarousel();
    this.readyToInitCarouselSubj.next(null)
  };
  initCarousel = () => {
    this.degree = 360 / this.cards.length;
    this.carousel.style.transform = `translateZ(-${this._zAxisValue}px)`;
    this.updateCarouselItems();
  };

  initCards() {
    if(this.allowAnimationIfCaourselIsEmpty && this.cards.length === 0){
      this.cards= Array(8)
      .fill(null)
      .map(() => {
        return new CarouselZeroCardParams({});
      });
    }
    else{
      this.allowAnimationIfCaourselIsEmpty=false
    }
    this.cards.map((targetCard: CarouselZeroCardParams, index0: number) => {
      // @ts-ignore
      targetCard.card.click = targetCard.option.click =this.updateCarousel(targetCard);
      targetCard.option.isPresent = index0 === 0;
      // @ts-ignore
      targetCard.id =index0
    });
  }

  updateCarouselItems() {
    this.cards.forEach((carouselItem, index0) => {
      carouselItem.card.style.transform = `
      rotateY(${index0 * this.degree}deg) translateZ(${
        this._zAxisValue
      }px) scale(${this.nonChosenScaleFactor})
      `;
      if (carouselItem.option.isPresent) {
        carouselItem.card.style.opacity = '1';
        carouselItem.card.style.transform = `rotateY(${
          index0 * this.degree
        }deg) translateZ(${this._zAxisValue}px)`;
      }
    });
  }

  updateCarousel(targetCard: CarouselZeroCardParams) {
    return (extrasPredicate = (card: CarouselZeroCardParams) => {}) => {

      this.cards.forEach((card, index0) => {
        card.card.style.transform = card.card.style.transform
          .split(`scale(${this.nonChosenScaleFactor})`)
          .join('');
          // @ts-ignore
        if (targetCard.id === card.id) {
          card.option.isPresent = true;
          card.card.style.opacity = '1';
          this.carousel.style.transform = `translateZ(-${
            this._zAxisValue
          }px) rotateY(-${index0 * this.degree}deg)`;



        } else {
          card.option.isPresent = false;
          card.card.style.opacity = '.5';
          card.card.style.transform += ` scale(${this.nonChosenScaleFactor})`;
        }
        extrasPredicate(card);

        this.updateCarouselItems();
      });
    };
  }


}

export class CarouselZeroCardParams {
  constructor(params: Partial<CarouselZeroCardParams> = {}) {
    Object.assign(this, {
      ...params,
    });
  }
  // this is the identifier for interalns
  id:number
  option = new WMLUIProperty({
    style: {
      background: `linear-gradient(${generateRandomNumber(
        360
      )}deg,${generateRandomColor()},${generateRandomColor()},${generateRandomColor()})`,
    },
  });
  card = new WMLUIProperty();
  cardProps = new WMLCustomComponent({
    cpnt:CardTwoComponent,
    params:new CardTwoParams()
  })
}
